<template>
  <v-container fluid tag="section">
    <v-layout align-center justify-center>
      <v-card width="800" class="mx-auto mt-5">
        <v-card-text>
          <v-stepper v-model="signupStep">
              <v-stepper-header>
                  <v-divider></v-divider>
                <template v-for="(step,n) in stepper_header">
                    <v-stepper-step
                        :key="n"
                        :complete="stepComplete(n+1)"
                        :step="n+1"
                        :color="stepStatus(n+1)">
                        {{ step.title }}
                    </v-stepper-step>
                    <v-divider></v-divider>
                </template>
              </v-stepper-header>
              <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-row class="justify-space-around mt-0">
                        <v-col cols="4">
                            <v-card color="ocean" @click="selectType(accountType.personal)">
                                <v-card-text class="white--text text-h3 text-center">{{ $t('signup.personAccount') }}</v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card color="primary" @click="selectType(accountType.company)">
                                <v-card-text class="white--text text-h3 text-center">{{ $t('signup.companyAccount') }}</v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-radio-group
                            v-model="item.accountType"
                            class="px-0 mt-0 account-radio"
                            hide-details
                            row
                        >
                            <v-radio
                                :value="accountType.personal"
                            ></v-radio>
                            <v-radio
                                :value="accountType.company"
                            ></v-radio>
                        </v-radio-group>
                    </v-row>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                      <v-card class="mt-0">
                          <v-form ref="form" v-model="valid" lazy-validation>
                              <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        :rules="usernameRules" 
                                        :label="$t('user_mgr.username')" 
                                        v-model="item.username" 
                                        :hint="username_hint"
                                        validate-on-blur />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        class="purple-input"
                                        :label="$t('user_mgr.nickname')"
                                        v-model="item.nickname"
                                        :rules="fieldRules"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field 
                                        :rules="fieldRules"
                                        :label="$t('user_mgr.password1')" 
                                        v-model="item.password1" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field 
                                        :rules="fieldRules"
                                        :label="$t('user_mgr.password2')" 
                                        v-model="item.password2" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        class="purple-input"
                                        :label="$t('user_mgr.first_name')"
                                        v-model="item.first_name"
                                        :rules="fieldRules"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        class="purple-input"
                                        :label="$t('user_mgr.email')"
                                        v-model="item.email"
                                        :rules="fieldRules"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        class="purple-input"
                                        :label="$t('user_mgr.phone')"
                                        v-model="item.phone"
                                        :rules="fieldRules"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        class="purple-input"
                                        :label="$t('user_mgr.line_account')"
                                        v-model="item.line_account"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        class="purple-input"
                                        :label="$t('user_mgr.company_name')"
                                        v-model="item.company_name"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        class="purple-input"
                                        :label="$t('user_mgr.job_title')"
                                        v-model="item.job_title"
                                    />
                                </v-col>

                                <template v-if="item.accountType==1">
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            class="purple-input"
                                            :label="$t('company_mgr.name')"
                                            v-model="item.company_name"
                                        />
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            class="purple-input"
                                            :rules="companyIDRules" 
                                            :label="$t('company_mgr.ID')"
                                            v-model="item.company_id" 
                                            :hint="company_id_hint"
                                            validate-on-blur 
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            class="purple-input"
                                            :label="$t('company_mgr.phone')"
                                            v-model="item.company_phone"
                                        />
                                    </v-col>
                                </template>
                            </v-row>
                          </v-form>
                      </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                      <v-card>
                          
                      </v-card>
                  </v-stepper-content>
              </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="info" class="text-h4" @click="nextStep">
              <template v-if="signupStep==1">
                  {{ $t('signup.next') }}
              </template>
              <template v-else-if="signupStep==2">
                  {{ $t('common.signup') }}
              </template>
              <template v-else>
                  {{ $t('signup.login') }}
              </template>
          </v-btn>
          <template>
              <v-btn v-if="signupStep==2" class="text-h4" @click="prevStep">
                  {{ $t('common.back') }}
              </v-btn>
          </template>
        </v-card-actions>
      </v-card>
        <dialog-message
            :dialog_message="dialog_message"
            :message="message"
        ></dialog-message>
    </v-layout>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
export default {
    name: "signup",
    data:(vm) => ({
        valid: true,
        showPassword: false,
        account: "",
        password: "",
        signupStep: 1,
        stepper_header: [
            {title: vm.$i18n.t('signup.chooseUserRole')},
            {title: vm.$i18n.t('signup.fillinData')},
            {title: vm.$i18n.t('signup.complete')},
        ],
        accountType: {
            personal: 0,
            company: 1,
        },
        item: {
            username: "",
            nickname: "",
            password1: "",
            password2: "",
            first_name: "",
            accountType: null,
            email: "",
            phone: "",
            expired_time: "",
            line_account: "",
            company_name: "",
            job_title: "",
            company_id: null,
            company_phone: "",
        },
        dialog_message: false,
        message: {
            title: "",
            text: ""
        },
        fieldRules: [v => !!v || vm.$i18n.t("common.missing")],
        username_hint: "",
        company_id_hint: "",
        nameValid: true,
        idValid: true,
        usernameRules: [v => !!v || vm.$i18n.t("common.missing"), v => vm.nameValid || vm.$i18n.t("signup.invalidUsername")],
        companyIDRules: [v => !!v || vm.$i18n.t("common.missing"), v => vm.idValid || vm.$i18n.t("signup.invalidCompanyID"), v => v.length==8 || vm.$i18n.t("signup.invalidFormat")],
    }),
    watch:{
        'item.username':{
            handler: function(val){
                if(val!=''){
                    this.checkUserName()
                } else {
                    this.username_hint = ""
                }
            }
        },
        'item.company_id':{
            handler: function(val){
                if(val!=''){
                    this.checkCompanyID()
                } else {
                    this.company_id_hint = ""
                }
            }
        }
    },
    methods: {
        ...mapMutations(['setTitle']),
        stepComplete(step) {
            return this.signupStep > step
        },
        stepStatus(step) {
            return this.signupStep > step ? 'green' : 'blue'
        },
        nextStep() {
            switch(this.signupStep) {
                case 1:
                    if(this.item.accountType != null){
                        this.signupStep = 2
                    }
                    break
                case 2:
                    this.createAccount()
                    break
                case 3:
                    this.$router.push('/login')
                    break
            }
        },
        prevStep() {
            this.signupStep = 1
        },
        selectType (type){
            this.item.accountType = type
        },
        createAccount() {
            let password_valid = this.checkPassword();
            if (!password_valid) {
                return;
            }
            switch (this.item.accountType){
                case this.accountType.personal:
                    this.createPersonalAccount()
                    break;
                case this.accountType.company:
                    this.createCompanyAccount()
                    break;
            }
        },
        checkPassword() {
            let is_valid = true;
            if (this.password1 != this.password2) {
                alert(this.$i18n.t("user_mgr.passwordNotMatch"));
                is_valid = false;
            } else if (this.password1 == "") {
                alert(this.$i18n.t("user_mgr.missingPassword"));
                is_valid = false;
            }
            return is_valid;
        },
        createPersonalAccount() {
            let data = {
                username: this.item.username,
                first_name: this.item.first_name,
                password: this.item.password1,
                email: this.item.email,
                nickname: this.item.nickname,
                phone: this.item.phone,
                accountType: this.item.accountType,
                max_urban_renewal: this.item.max_urban_renewal,
                line_account: this.item.line_account,
                company_name: this.item.company_name,
                job_title: this.item.job_title
            }
            this.sendData(data)
        },
        createCompanyAccount() {
            let data = {
                username: this.item.username,
                first_name: this.item.first_name,
                password: this.item.password1,
                email: this.item.email,
                nickname: this.item.nickname,
                phone: this.item.phone,
                accountType: this.item.accountType,
                max_urban_renewal: this.item.max_urban_renewal,
                line_account: this.item.line_account,
                company_name: this.item.company_name,
                job_title: this.item.job_title,
                company_id: this.item.company_id,
                company_phone: this.item.company_phone,
            }
            this.sendData(data)
        },
        sendData(data) {
            if (!this.$refs.form.validate()) {
                return;
            }
            const currentObj = this;
            // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            const url = process.env.VUE_APP_SERVER_URL + "/backend/createUser/";
            let config = {
                headers: {
                "Content-Type": "application/json",
                // Authorization: "token " + this.token
                }
            };
            const _this = this
            this.axios
                .post(url, data, config)
                .then(function(response) {
                    _this.signupStep = 3
                    // alert('save success')
                })
                .catch(function(error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("signup.failed");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        checkCompanyID() {
            const currentObj = this;
            const url =
                process.env.VUE_APP_SERVER_URL +
                "/backend/createUser/checkCompanyID/";
            let config = {
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: "Token " + this.token,
                },
                params: {
                    company_id: this.item.company_id
                },
            };

            this.axios
                .get(url, config)
                .then(function (response) {
                currentObj.loading = false;
                let request_data = response.data
                if (request_data.status == 'OK'){
                    currentObj.company_id_hint = currentObj.$t("signup.validCompanyID")
                    currentObj.idValid = true
                } else {
                    currentObj.company_id_hint = currentObj.$t("signup.invalidCompanyID")
                    currentObj.idValid = false
                }
                })
                .catch(function (error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("get-api-fail");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
        checkUserName() {
            const currentObj = this;
            const url =
                process.env.VUE_APP_SERVER_URL +
                "/backend/createUser/checkUsername/";
            let config = {
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: "Token " + this.token,
                },
                params: {
                    username: this.item.username
                },
            };

            this.axios
                .get(url, config)
                .then(function (response) {
                currentObj.loading = false;
                let request_data = response.data
                if (request_data.status == 'OK'){
                    currentObj.username_hint = currentObj.$t("signup.validUsername")
                    currentObj.nameValid = true
                } else {
                    currentObj.username_hint = currentObj.$t("signup.invalidUsername")
                    currentObj.nameValid = false
                }
                })
                .catch(function (error) {
                console.log(error);
                console.log(error.response);
                currentObj.loading = false;
                currentObj.message.title = currentObj.$t("get-api-fail");
                currentObj.message.text = JSON.stringify(error.response.data);
                currentObj.dialog_message = !currentObj.dialog_message;
                });
        },
    },
    mounted(){
        this.setTitle(this.$i18n.t('common.signup'))
    }
};
</script>

<style lang="sass" scoped>
.account-radio
    width: 100%
.account-radio::v-deep .v-input--radio-group__input
    justify-content: space-around
    .v-radio
        margin: 0

</style>
